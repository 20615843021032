<template>
    <!-- backgroundColor: '#f4f4f4', -->
    <div
        id="loadContent"
        :style="{
            overflow: 'auto',
            width: '100%'
        }"
    >
        <div style="padding: 5px; height: 100%">
            <el-row :gutter="10" style="margin: 5px">
                <el-col style="text-align: center" :span="3">
                    <div class="rejectClass">
                        <div class="rejectSpan">本月活动</div>
                    </div>
                    <el-card :body-style="{ height: '40px', lineHeight: '20px' }">
                        <h2>{{ monHuiyi }}</h2>
                    </el-card>
                </el-col>
                <el-col style="text-align: center" :span="3">
                    <div class="rejectClass" style="border-top: 49px solid #303133">
                        <div class="rejectSpan">本周活动</div>
                    </div>
                    <el-card :body-style="{ height: '40px', lineHeight: '20px' }">
                        <h2>{{ weekHuiyi }}</h2>
                    </el-card>
                </el-col>
                <el-col style="text-align: center" :span="3">
                    <div class="rejectClass" style="border-top: 49px solid #409eff">
                        <div class="rejectSpan">进行中</div>
                    </div>
                    <el-card :body-style="{ height: '40px', lineHeight: '20px' }">
                        <h2>{{ doingHuiyi }}</h2>
                    </el-card>
                </el-col>
                <el-col style="text-align: center" :span="3">
                    <div class="rejectClass" style="border-top: 49px solid rgb(238, 238, 238)">
                        <div class="rejectSpan" style="color: black">已结束</div>
                    </div>
                    <el-card :body-style="{ height: '40px', lineHeight: '20px' }">
                        <h2>{{ finishHuiyi }}</h2>
                    </el-card>
                </el-col>
            </el-row>
            <el-form>
                <el-row :gutter="10" style="padding: 10px">
                    <el-col :span="5">
                        <el-form-item label="会议名称">
                            <el-input placeholder="请输入会议名称" v-model="queryModel.huiyi_name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="会议状态">
                            <el-switch
                                active-text="已结束"
                                inactive-text="未结束"
                                active-color="#ff4949"
                                inactive-color="#13ce66"
                                :active-value="1"
                                :inactive-value="2"
                                v-model="queryModel.dtme_status"
                            ></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" style="text-align: left">
                        <!-- <el-button
              type="primary"
              :size="size"
              text
              @click="getMyDeleteEvent"
              >已删除会议</el-button
            > -->

                        <el-switch
                            active-text="已删除"
                            inactive-text="正常"
                            active-color="#ff4949"
                            inactive-color="#13ce66"
                            :active-value="0"
                            :inactive-value="1"
                            v-model="queryModel.status"
                        ></el-switch>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item label="会议时间">
                            <el-date-picker :size="size" v-model="queryModel.mon" type="month" format="YYYY-MM" value-format="YYYY-MM"></el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6" style="text-align: left">
                        <el-button type="primary" :size="size" icon="search" @click="getData">搜索</el-button>
                        <el-button
                            type="danger"
                            :size="size"
                            icon="delete"
                            @click="
                                () => {
                                    queryModel = {};
                                    getData();
                                }
                            "
                        >
                            清除
                        </el-button>
                        <el-button type="primary" :size="size" icon="plus" @click="gotoNewEvents">新建会议</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <!-- <el-row style="padding: 10px">
       
      </el-row> -->
            <div
                style="padding: 10px; border-top: 1px solid #eee; overflow: auto"
                :style="{
                    maxHeight: mainHeight
                }"
            >
                <el-row v-if="dataList && dataList.length > 0">
                    <el-col v-for="d in dataList" :key="d.id" style="border-bottom: 1px solid #eee; margin-bottom: 4px">
                        <el-card shadow="hover" :body-style="{ padding: '10px' }">
                            <el-row :gutter="20">
                                <el-col :span="3" style="text-align: left">
                                    <img
                                        :src="d.会议图片"
                                        style="max-height: 120px; height: 100%; width: 100%"
                                        :style="{
                                            filter: d.huiyi_status == -1 ? 'grayscale(100%)' : ''
                                        }"
                                    />
                                    <div v-if="d.status == 0" class="rejectClass" style="border-top: 40px solid grey">
                                        <div class="rejectSpan">已删除</div>
                                    </div>
                                    <div v-else>
                                        <div v-if="d.huiyi_status == 1" class="rejectClass" style="border-top: 49px solid #409eff">
                                            <div class="rejectSpan">进行中</div>
                                        </div>

                                        <div v-else-if="d.huiyi_status == -1" class="rejectClass" style="border-top: 40px solid rgb(238, 238, 238)">
                                            <div class="rejectSpan" style="color: black">已结束</div>
                                        </div>

                                        <div v-else-if="d.huiyi_status == 0" class="rejectClass" style="border-top: 40px solid grey">
                                            <div class="rejectSpan">未开始</div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="11" style="text-align: left">
                                    <div style="margin-bottom: 10px">
                                        <span style="font-weight: bold; font-size: 17px">{{ d.会议名称 }}</span>
                                        <span style="color: #999; font-size: 13px" v-if="d.huiyi_status == 0">【未开始】</span>
                                    </div>
                                    <div style="margin-bottom: 10px">
                                        <span style="color: #999; font-size: 13px"></span>
                                        <span style="color: #999; font-size: 13px">{{ d.会议简介 }}</span>
                                    </div>
                                    <div style="margin-bottom: 10px">
                                        <span style="color: #999; font-size: 13px">{{ d.会议开始时间 }}</span>
                                        <span style="color: #999; font-size: 13px">~</span>
                                        <span style="color: #999; font-size: 13px">{{ d.会议结束时间 }}</span>
                                    </div>
                                    <div style="margin-bottom: 10px">
                                        <span style="color: #999; font-size: 13px"></span>
                                        <span style="color: #999; font-size: 13px">{{ d.详细地址 }}</span>
                                    </div>
                                </el-col>
                                <el-col :span="5" style="text-align: left">
                                    <div style="margin-bottom: 10px">
                                        <span style="color: #999; font-size: 13px">地址：</span>
                                        <span style="color: #999; font-size: 13px">{{ d.会议地址 }}</span>
                                    </div>
                                    <div style="margin-bottom: 10px">
                                        <span style="color: #999; font-size: 13px">类型：</span>
                                        <span style="color: #999; font-size: 13px">{{ d.会议类型 }}</span>
                                    </div>

                                    <div style="margin-bottom: 10px">
                                        <span style="color: #999; font-size: 13px">注册量：</span>
                                        <span style="color: #999; font-size: 13px">{{ d.注册数量 }}</span>
                                    </div>
                                    <!-- <div v-for="(value, key, i) in d" :key="i">
                  <div
                    v-if="
                      key != imgName &&
                      key != 'id' &&
                      key != 'status' &&
                      key != '创建时间' &&
                      key != 'create_dtme' &&
                      key != 'huiyi_status' &&
                      key != 'huiyi_code'
                    "
                    style="height: 25px"
                  >
                    <span
                      v-if="i == 0 ? i == 0 : i == 1"
                      style="font-weight: bold; font-size: 18px"
                      >{{ key }}：</span
                    >
                    <span v-else style="color: #999; font-size: 13px"
                      >{{ key }}：</span
                    >
                    <span
                      v-if="i == 0 ? i == 0 : i == 1"
                      style="font-weight: bold; font-size: 18px"
                      >{{ value }}</span
                    >
                    <span v-else style="color: #999; font-size: 13px">{{
                      value
                    }}</span>
                  </div>
                </div> -->
                                </el-col>
                                <!-- <el-col :span="4">
                 <el-progress
                  :text-inside="true"
                  :stroke-width="10"
                  :percentage="70"
                /> 
              </el-col>-->
                                <el-col :span="5" style="text-align: right">
                                    <div style="margin-bottom: 4px">
                                        <el-button v-if="d.status != 0" type="primary" :size="size" style="margin-right: 5px" icon="view" @click="gotoEvent(d)">进入</el-button>
                                    </div>
                                    <div style="margin-bottom: 4px">
                                        <el-button
                                            v-if="d.status != 0 && d.huiyi_status != -1"
                                            type="primary"
                                            :size="size"
                                            style="margin-right: 5px"
                                            icon="edit"
                                            @click="editEvent(d)"
                                        >
                                            编辑
                                        </el-button>
                                    </div>
                                    <div>
                                        <el-button v-if="d.status != 0" type="plain" :size="size" style="margin-right: 5px" icon="delete" @click="deleteEvent(d.huiyi_code)">
                                            删除
                                        </el-button>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>
                </el-row>
                <el-empty v-else description="暂无进行中/未开始活动,点击已结束查看已结束活动,或点击新建会议,建立新会议" />
            </div>
            <div style="height: 30px; line-height: 30px">
                <el-pagination
                    :total="page.totalResult"
                    :page-size="page.pageSize"
                    :current-page="page.startPage"
                    background
                    small
                    :page-sizes="[3, 6, 9]"
                    layout="sizes,prev, pager, next"
                    @size-change="sizeChange"
                    @current-change="currentChange"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
import { myMixin } from './../mixin.js';
const { errorMsgBox, successMsgBox, confirm, apiAll, withLoading } = myMixin();
let size = ref('mini');
let model_simple_cloud_huiyi = reactive({});
let doingHuiyi = ref(0);
let weekHuiyi = ref(0);
let monHuiyi = ref(0);
let finishHuiyi = ref(0);
let dataList = reactive([]);
let columns = reactive([]);
let page = reactive({ pageSize: 6, startPage: 1, totalResult: 0 });
let mainHeight = ref(0);
let queryModel = reactive({ dtme_status: 0 });
let currentHuiyiId = ref(0);
onMounted(() => {
    getInit();
});
function getInit() {
    mainHeight.value = window.innerHeight - 320 + 'px';
    getData();
}
function editEvent(row) {
    window.sessionStorage.setItem('huiyiCode', row.huiyi_code);
    router.push('newEvents');
}
//进入活动
function gotoEvent(d) {
    //sessionStorage.clear();
    // window.open(
    //   window.localStorage.getItem("kaola_baseURL") +
    //     "/eventManager?thirdCode=" +
    //     d.huiyi_code +
    //     "#/event_set"
    // );

    window.open(window.localStorage.getItem('kaola_baseURL') + '/eventManager?thirdCode=' + d.huiyi_code + '#/event_set');
}
function gotoNewEvents() {
    window.sessionStorage.removeItem('huiyiCode');
    router.push('newEvents');
    // //this.$router.push("newEvents");
    // this.$parent.$parent.$parent.$parent.$parent.$parent.gotoPage(
    //   "newEvents",
    //   "创建会议"
    // );
}
async function getMyDeleteEvent() {
    let data = {};
    let params = {};
    params.page = page;
    data.params = JSON.stringify(params);
    const res = await withLoading(apiAll(data, '/getMyDeleteEvent'));
    if (res.code >= 0) {
        dataList = res.extra;
        page.totalResult = res.page.totalResult;
    } else {
        errorMsgBox(res.message);
    }
}
function deleteEvent(id) {
    currentHuiyiId = id;
    confirm('确认删除吗？', doDeleteById);
}
async function doDeleteById() {
    let data = {};
    let params = {};
    params.ephemeralParam = currentHuiyiId;
    data.params = JSON.stringify(params);
    const res = await withLoading(apiAll(data, '/deleteMyEvent'));
    if (res.code >= 0) {
        successMsgBox(res.message);
        getInit();
    } else {
        errorMsgBox(res.message);
    }
}
async function getData() {
    let data = {};
    let params = {};
    params.ephemeralParam = queryModel;
    params.page = page;
    data.params = JSON.stringify(params);
    const res = await withLoading(apiAll(data, '/getMyEvent'));
    if (res.code >= 0) {
        dataList = res.extra.result;
        doingHuiyi.value = res.extra.doingHuiyiCount ? res.extra.doingHuiyiCount : 0;
        finishHuiyi.value = res.extra.finishHuiyiCount ? res.extra.finishHuiyiCount : 0;
        weekHuiyi.value = res.extra.weekHuiyiCount ? res.extra.weekHuiyiCount : 0;
        monHuiyi.value = res.extra.monHuiyiCount ? res.extra.monHuiyiCount : 0;
        page.totalResult = res.page.totalResult;
    } else {
        errorMsgBox(res.message);
    }
}
function searchData() {
    alert('这里是搜索！');
}
function clearSearch() {
    alert('清除所有搜索条件！');
}
function sizeChange(val) {
    page.startPage = 1;
    page.pageSize = val;
    getData();
}
function currentChange(val) {
    page.startPage = val;
    getData();
}
</script>
<style lang="less" scoped>
#loadContent {
    /deep/ .el-form-item {
        margin-bottom: 0;
    }
    .rejectClass {
        position: absolute;
        top: 0;
        //left: 0;
        width: 0;
        height: 0;
        border-top: 49px solid green;
        border-right: 156px solid transparent;
    }
    .rejectSpan {
        position: relative;
        top: -47px;
        left: -8px;
        width: 110px;
        color: #fff;
        font-size: 25px;
        -webkit-transform: scale(0.6);
        transform: scale(0.5);
    }
}
</style>
