import axios from "axios";
axios.interceptors.request.use(
	(config) => {
		// 在发送请求之前做些什么
		//判断是否存在token，如果存在将每个页面header都添加token
		if (localStorage.getItem("kaola_accessToken")) {
			// config.headers.common["Authorization"] =
			//   sessionStorage.getItem("accessToken");
			config.headers["Authorization"] =
				localStorage.getItem("kaola_accessToken");
		}
		// 拦截失效、非法请求
		// else{
		//   config.cancelToken = new cancelToken((c) => {
		//     this.errorMsgBox("登录失效，请重新登录")
		//   });
		// }

		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		alert(error);

		if (error.response) {
			switch (error.response.status) {
				case 401:
					this.$store.commit("del_token");
					router.replace({
						path: "/login",
						query: {
							redirect: router.currentRoute.fullPath,
						}, //登录成功后跳入浏览的当前页面
					});
					break;
				case 500:
					Message;
			}
			return Promise.reject(error.response.data);
		} else {
			//TODO:网络超时反应，返回指定提示内容，使用时，需要根据
			return window.const.net_connected_error;
		}
	}
);
//根据路径来选择自动选择接口前缀
//axios.defaults.baseURL = "https://koalahuiyi.com/newweb/";
if (!localStorage.getItem("kaola_baseURL")) {
	localStorage.setItem("kaola_baseURL", window.location.origin);
}
if (!localStorage.getItem("kaola_baseHost")) {
	localStorage.setItem("kaola_baseHost", window.location.host);
}
axios.defaults.baseURL = localStorage.getItem("kaola_baseURL") + "/newweb/";
//axios.defaults.baseURL = "http://localhost:8245";
export default axios;